import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { getWorker, updateWorker } from '@/@core/queries/workers'
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import { checkIfWorkerExistsInUser, updateUser } from '@/@core/queries/user'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

export default function useWorkerEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const worker = ref({})
  const isSubmitting = ref(false)

  async function show() {
    isSubmitting.value = true
    store
      .dispatch('workerStoreModule/createWorker', { query: getWorker, variables: { query: { _id: route.value.params.id } } })
      .then(({ data }) => {
        const d = data?.data.worker || {}
        worker.value = {
          ...d,
          supervisors: d?.supervisors?.map(({ _id }) => _id),
          locations: d?.locations?.map(({ _id }) => _id),
        }
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.worker_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  function update(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value
    if (data.email.value) payload.email = data.email.value
    if (data.supervisors.value) payload.supervisors = { link: data.supervisors.value }
    if (data.locations.value) payload.locations = { link: data.locations.value }
    if (data.roles.value) payload.roles = data.roles.value
    if (data.privileges?.value) payload.privileges = data.privileges?.value
    payload.isComplaintManager = data.isComplaintManager?.value || false

    const role = data.privileges.value ? data.privileges.value : null
    const name = data.name?.value
    const defaultPassword = '12345678'
    // eslint-disable-next-line no-underscore-dangle
    const _id = route.value.params.id
    store
      .dispatch('workerStoreModule/update', {
        query: updateWorker,
        variables: { query: { _id }, data: payload },
      })
      .then(() => {
        // if (role !== 'employee') {
          store
            .dispatch('workerStoreModule/checkUserExist', {
              query: checkIfWorkerExistsInUser,
              variables: { query: { custom_data: { worker_id: _id } } },
            }).then(async user => {
              if (!user?.data?.data?.user) {
                if (payload.email) {
                  useJwt.register({ email: payload.email.toLowerCase(), password: defaultPassword, name }).then(async () => {
                    try {
                      const logUser = await useJwt.login({ email: payload.email.toLowerCase(), password: defaultPassword })
                      await useJwt.updateDefaultData({
                        id: logUser.id,
                        username: name,
                        project: '',
                        role,
                        worker_id: _id,
                      })
                      await useJwt.realm.allUsers[logUser.id].logOut()
                      showSuccessMessage(i18n.t('message.worker_updated'))
                    } catch (e) {
                      console.log(e)
                      showErrorMessage(e.message)
                    } finally {
                      router.push({ name: 'organization-worker-list' })
                      isSubmitting.value = false
                    }
                  }).catch(error => {
                    console.log(error)
                    showErrorMessage(error.message)
                    router.push({ name: 'organization-worker-list' })
                    isSubmitting.value = false
                  })
                } else {
                  showSuccessMessage(i18n.t('message.worker_updated'))
                  router.push({ name: 'organization-worker-list' })
                  isSubmitting.value = false
                }
              } else {
                try {
                  store
                    .dispatch('workerStoreModule/updateUser', {
                      query: updateUser,
                      variables: { query: { id: user?.data?.data?.user?.id }, data: { deleted: false } },
                    })
                    .then(async () => {
                      await useJwt.updateDefaultData({
                        id: user?.data?.data?.user.id,
                        username: name,
                        project: '',
                        role,
                        worker_id: _id,
                      })
                      showSuccessMessage(i18n.t('message.worker_updated'))
                      router.push({ name: 'organization-worker-list' })
                    }).catch((error) => {
                      console.log(error)
                      showErrorMessage(i18n.t('message.worker_update_error'))
                    }).finally(() => {
                      isSubmitting.value = false
                    })
                } catch (e) {
                  console.log(e)
                  showErrorMessage(e.message)
                  router.push({ name: 'organization-worker-list' })
                  isSubmitting.value = false
                }
              }
            })
            .catch((error) => {
              console.log(error)
              showErrorMessage(i18n.t('message.worker_update_error'))
              isSubmitting.value = false
            })
        // } else if (role === 'employee') {
        //   store
        //     .dispatch('workerStoreModule/checkUserExist', {
        //       query: checkIfWorkerExistsInUser,
        //       variables: { query: { custom_data: { worker_id: _id } } },
        //     }).then(async user => {
        //       if (user?.data?.data?.user) {
        //         store
        //           .dispatch('workerStoreModule/checkUserExist', {
        //             query: updateUser,
        //             variables: { query: { id: user?.data?.data?.user?.id }, data: { deleted: true } },
        //           })
        //           .then(() => {
        //             showSuccessMessage(i18n.t('message.worker_updated'));
        //             router.push({ name: 'organization-worker-list' })
        //           }).catch(() => {
        //             showErrorMessage(i18n.t('message.worker_update_error'))
        //           }).finally(() => {
        //             isSubmitting.value = false
        //           })
        //       } else {
        //         showSuccessMessage(i18n.t('message.worker_updated'))
        //         router.push({ name: 'organization-worker-list' })
        //         isSubmitting.value = false
        //       }
        //     }).catch(() => {
        //       showErrorMessage(i18n.t('message.worker_update_error'))
        //       isSubmitting.value = false
        //     })
        // } else {
        //   showSuccessMessage(i18n.t('message.worker_updated'))
        //   router.push({ name: 'organization-worker-list' })
        //   isSubmitting.value = false
        // }
      }).catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.worker_update_error'))
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    worker,
    isSubmitting,
  }
}
